import styled from "styled-components";

export const EventDate = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  margin-bottom: 15px;

  i {
    margin: 0 10px;
  }
`;

export const MoreEvents = styled.div`
  text-align: right;
`;
