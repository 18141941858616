import { parse } from "date-fns";
import React from "react";

import { IMAGES_PATH } from "../../const";
import { COLORS } from "../../theme/colors";
import { Container } from "../common/container";
import { LIST_ITEM_TYPES, ListItem } from "../common/list-item";
import { List } from "../common/list-item/list-item.styled";
import { Section } from "../common/section";
import { SectionContent } from "../common/section-content";
import { SectionHeader } from "../common/section-header";
import { SectionImage } from "../common/section-image";
import { events } from "./data";
import { MoreEvents } from "./events.styled";

const getDate = (date) => parse(date, "dd.MM.yyyy", new Date()).setHours(0, 0, 0, 0);

const currentDate = new Date().setHours(0, 0, 0, 0);

export const Events = () => (
  <Section color={COLORS.dark} id="koncerty">
    <Container>
      <SectionHeader color={COLORS.dark} title="Koncerty" />
      <SectionImage align="left" alt="Koncerty" src={`${IMAGES_PATH}/2.jpg`} />
      <SectionContent align="right">
        <List>
          {events
            .filter((a) => getDate(a.date) > new Date())
            .map(({
              title, place, date, link, tickets,
            }) => (
              <ListItem
                key={`${LIST_ITEM_TYPES.event}-${date}`}
                date={date}
                disabled={getDate(date) < currentDate}
                link={link}
                place={place}
                tickets={tickets}
                title={title}
                type={LIST_ITEM_TYPES.event}
              />
            ))}
          <MoreEvents>Więcej wydarzeń wkrótce...</MoreEvents>
        </List>
      </SectionContent>
    </Container>
  </Section>
);
