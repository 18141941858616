import React, { useEffect, useState } from "react";

import { IMAGES_PATH } from "../../../const";
import { LoaderWrapper } from "./loader.styled";

export const Loader = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(false);
    }, 3000);
  }, []);

  return (
    <div>
      {isVisible && (
        <LoaderWrapper>
          <img
            alt="Loader"
            src={`${IMAGES_PATH}/LIT_logo_glowing_small.png`}
            width="100"
          />
        </LoaderWrapper>
      )}
    </div>
  );
};
