import React from "react";

import { OFFICIAL_VIDEO_URL } from "../../const";
import { COLORS } from "../../theme/colors";
import { Container } from "../common/container";
import { Section } from "../common/section";
import { SectionHeader } from "../common/section-header";
import { VideoWrapper } from "./video.styled";

export const Video = () => (
  <Section color={COLORS.dark} id="wideo">
    <Container>
      <SectionHeader color={COLORS.dark} title="Wideo" />
      <VideoWrapper>
        <iframe
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          frameBorder="0"
          height="315"
          src={OFFICIAL_VIDEO_URL}
          title="YouTube video player"
          width="560"
        />
      </VideoWrapper>
    </Container>
  </Section>
);
