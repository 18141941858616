import styled from "styled-components";

import { MOBILE } from "../../const";

export const HeroWrapper = styled.div`
  overflow: hidden;
`;

export const HeroContent = styled.div`
  position: absolute;
  top: 0;
  width: 100%;

  @media ${MOBILE} {
    height: 500px;
  }
`;

export const HeroImage = styled.img`
  height: 100vh;
  margin-top: 50px;
  object-fit: cover;
  width: 100%;

  @media ${MOBILE} {
    height: 600px;
    min-height: 600px;
  }
`;

export const HeroTitle = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-end;
  padding-bottom: 80px;
  position: absolute;
  top: 0;

  @media ${MOBILE} {
    margin-top: 0;
    height: 600px;
    justify-content: flex-end;
    padding: 20px;
  }

  & h1 {
    font-size: 46px;
    margin: 0 0 10px 0;

    @media ${MOBILE} {
      font-size: 40px;
    }
  }

  & p {
    font-size: 20px;
  }
`;

export const StreamingIcons = styled.div`
  align-items: center;
  display: flex;
  margin-top: 30px;

  a {
    margin-right: 15px;
  }
`;

export const HeroCoverWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const HeroCover = styled.img`
  box-shadow: 0 0 5px white;
  height: 180px;
  margin-right: 30px;
  width: 180px;

  @media ${MOBILE} {
    display: none;
  }
`;
