import styled from "styled-components";

export const VideoWrapper = styled.div`
  height: 0;
  padding-bottom: 56.25%;
  padding-top: 25px;
  position: relative;

  & iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;
