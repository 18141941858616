import styled from "styled-components";

import { MOBILE } from "../../../const";

export const SectionImg = styled.img`
  height: 450px;
  left: ${({ align }) => (align === "left" ? 0 : "auto")};
  object-fit: cover;
  object-position: top;
  position: absolute;
  right: ${({ align }) => (align === "right" ? 0 : "auto")};
  top: 20px;
  width: 450px;

  @media ${MOBILE} {
    width: 100%;
    height: 250px;
    object-fit: cover;
    filter: brightness(1);
    object-position: ${({ alt }) => (alt === "Newsy" ? "0 -60px" : "top")};
    position: relative;
  }
`;
