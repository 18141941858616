import React from "react";

import { IMAGES_PATH } from "../../const";
import { Container } from "../common/container";
import {
  HeroContent,
  HeroCover,
  HeroCoverWrapper,
  HeroImage,
  HeroTitle,
  HeroWrapper,
  StreamingIcons,
} from "./hero.styled";
import { streaming } from "./streaming";

export const Hero = () => (
  <HeroWrapper>
    <HeroImage alt="Hero" src={`${IMAGES_PATH}/bg.jpg`} />
    <HeroContent>
      <Container>
        <HeroTitle>
          <HeroCoverWrapper>
            <HeroCover src={`${IMAGES_PATH}/mp.jpg`} />
            <div>
              <h1>Musimy przetrwać</h1>
              <div>
                Album już dostępny
                <br />
                <br />
                Posłuchaj na:
                <StreamingIcons>
                  {streaming.map(({ href, src, alt }) => (
                    <a key={alt} href={href} rel="noreferrer" target="_blank">
                      <img alt={alt} src={src} width="40" />
                    </a>
                  ))}
                </StreamingIcons>
              </div>
            </div>
          </HeroCoverWrapper>
        </HeroTitle>
      </Container>
    </HeroContent>
  </HeroWrapper>
);
