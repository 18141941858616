import "aos/dist/aos.css";

import AOS from "aos";
import React, { useEffect } from "react";
import { ParallaxProvider } from "react-scroll-parallax";

import { AppWrapper } from "./app.styled";
import { Loader } from "./components/common/loader";
import { Contact } from "./components/contact";
import { Events } from "./components/events";
import { Header } from "./components/header";
import { Hero } from "./components/hero";
import { News } from "./components/news";
import { Video } from "./components/video";

export const App = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <AppWrapper>
      <Loader />
      <ParallaxProvider>
        <Header />
        <Hero />
        <Events />
        <News />
        <Video />
        <Contact />
      </ParallaxProvider>
    </AppWrapper>
  );
};
