import styled from "styled-components";

import { MOBILE } from "../../../const";
import { COLORS } from "../../../theme/colors";

export const Item = styled.div`
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin-bottom: 20px;
  padding: 0px 0 20px;
  width: 100%;

  &:last-of-type {
    border: none;
    margin-bottom: 0;
  }
  
  a {
    transition: color ease-in 0.1s;
  }

  a:hover {
    color: ${COLORS.accent};
  }

  @media ${MOBILE} {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 20px;
  }
`;

export const List = styled.div`
  width: 100%;
`;

export const ItemDate = styled.div`
  align-items: center;
  color: silver;
  display: flex;
  font-size: 14px;
  margin-bottom: 15px;

  i {
    margin: 0 10px;
  }

  i:first-of-type {
    @media ${MOBILE} {
      margin-left: 0;
    }
  }
`;

export const ItemTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  padding-left: 10px;

  @media ${MOBILE} {
    padding: 0;
  }
`;

export const ItemInfo = styled.div`
  align-items: center;
  display: flex;

  & i {
    color: ${COLORS.accent};
  }

  & span {
    display: inline-block;
    font-size: 14px;
    line-height: 1.5;
    padding: 0 10px;

    @media ${MOBILE} {
      padding: 0;
    }
  }
`;

export const NoTicket = styled.div`
  @media ${MOBILE} {
    margin-top: 15px;
    padding-left: 0;
  }
`;
