import styled from "styled-components";

import { MOBILE } from "../../../const";

export const SectionWrapper = styled.div`
  background-color: ${({ color }) => color};
  min-height: 800px;
  overflow: hidden;
  padding: 80px 20px;

  & h2 {
    margin-bottom: 40px;
    margin-top: 0;
    padding: 0;
  }

  @media ${MOBILE} {
    min-height: ${({ height }) => height || 0};
  }
`;
