import React from "react";
import { Parallax } from "react-scroll-parallax";

import { useIsMobile } from "../../../hooks";
import {
  SectionHeaderH2,
  SectionHeaderSeparator,
  SectionHeaderText,
} from "./section-header.styled";

export const SectionHeader = ({ title, color }) => {
  const { isMobile } = useIsMobile();

  return (
    <Parallax disabled={isMobile} speed={2}>
      <SectionHeaderH2>
        <SectionHeaderText color={color}>{title}</SectionHeaderText>
        <SectionHeaderSeparator
          data-aos="zoom-in"
          data-aos-delay="150"
          data-aos-duration="400"
        />
      </SectionHeaderH2>
    </Parallax>
  );
};
