import {
  FB_URL, IG_URL, SPOTIFY_URL, TIKTOK_URL, YT_URL,
} from "../../const";

export const socials = [
  {
    link: FB_URL,
    name: "facebook",
  },
  {
    link: IG_URL,
    name: "instagram",
  },
  {
    link: YT_URL,
    name: "youtube",
  },
  {
    link: SPOTIFY_URL,
    name: "spotify",
  },
  {
    link: TIKTOK_URL,
    name: "tiktok",
  },
];
