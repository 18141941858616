import React, { useEffect, useState } from "react";

import { IMAGES_PATH, NEWS_URL } from "../../const";
import { COLORS } from "../../theme/colors";
import { Container } from "../common/container";
import { LIST_ITEM_TYPES, ListItem } from "../common/list-item";
import { List } from "../common/list-item/list-item.styled";
import { Section } from "../common/section";
import { SectionContent } from "../common/section-content";
import { SectionHeader } from "../common/section-header";
import { SectionImage } from "../common/section-image";

export const News = () => {
  const [posts, setPosts] = useState([]);

  const getPosts = () => {
    fetch(NEWS_URL).then((res) => res
      .json()
      .then(({ data }) => {
        setPosts(data);
      })
      .catch(() => {}));
  };

  useEffect(() => {
    getPosts();
  }, []);

  return (
    <Section color={COLORS.semiDark} id="newsy">
      <Container>
        <SectionHeader color={COLORS.semiDark} title="Newsy" />
        <SectionImage align="right" alt="Newsy" src={`${IMAGES_PATH}/1.jpg`} />
        <SectionContent align="left">
          <List>
            {posts
              ?.filter((post) => post?.message !== undefined)
              .slice(0, 3)
              .map(({
                // eslint-disable-next-line
                id, message, created_time, story,
              }) => (
                <ListItem
                  key={`${LIST_ITEM_TYPES.news}-${id}`}
                  date={new Date(created_time).toLocaleDateString("pl-PL")}
                  link={`https://facebook.com/${id}`}
                  place={story}
                  tickets={null}
                  title={`${message.substring(0, 150)}...`}
                  type={LIST_ITEM_TYPES.news}
                />
              ))}
            {(posts?.length === 0 || !posts) && (
              <p>Więcej informacji wkrótce...</p>
            )}
          </List>
        </SectionContent>
      </Container>
    </Section>
  );
};
