import styled from "styled-components";

import { MOBILE, ZLAYERS } from "../../const";
import { COLORS } from "../../theme/colors";

export const HeaderWrapper = styled.div`
  align-items: center;
  background-color: ${COLORS.dark};
  display: flex;
  height: 60px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${ZLAYERS.header};
`;

export const Logo = styled.img`
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-name: fade;
  left: 10px;
  max-width: 100%;
  position: absolute;
  top: 10px;
  width: 60px;
`;

export const Nav = styled.nav`
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;

  & ul,
  & ul li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  & ul {
    display: flex;
    height: 100%;
    justify-content: center;

    @media ${MOBILE} {
      flex-direction: column;
      align-items: center;
      margin-top: -30px;
    }
  }

  & ul li a {
    align-items: center;
    color: ${COLORS.light};
    display: flex;
    font-weight: bold;
    height: 100%;
    padding: 0 16px;
    position: relative;
    text-decoration: none;
    transition: background ease-in 0.1s;

    @media ${MOBILE} {
      height: 90px;
      font-size: 24px;
    }
  }

  & ul li a:hover {
    background-color: ${COLORS.accent};
  }

  @media ${MOBILE} {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background-color: ${COLORS.dark};
  }
`;

export const SocialWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  right: 10px;

  @media ${MOBILE} {
    position: fixed;
    bottom: 15px;
    left: 0;
    right: 0;
  }
`;

export const SocialLink = styled.a`
  & i {
    border-radius: 50%;
    color: ${COLORS.light};
    font-size: 30px;
    margin: 0 10px;
    transition: all ease-in 0.1s;
    width: 30px;

    &:hover {
      color: ${COLORS.accent};
    }
  }
`;

export const HamburgerWrapper = styled.div`
  display: none;
  position: absolute;
  right: 0;

  @media ${MOBILE} {
    display: block;
  }
`;
