export const events = [
  {
    date: "11.05.2022",
    link: "https://www.facebook.com/events/736810427306728",
    place: "Poznań",
    tickets: "https://www.biletomat.pl/festiwale/wielkie-grillowanie-uam-2022-poznan-11302/",
    title: "Wielkie Grillowanie UAM",
  },
  {
    date: "18.06.2022",
    link: "https://www.facebook.com/events/1153901665412558",
    place: "Poznań",
    tickets: "https://www.biletomat.pl/pop-rock/beyond-the-event-horizon-poznan-11274",
    title: "U Bazyla",
  },
  {
    date: "30.07.2022",
    link: "https://www.facebook.com/litowce",
    place: "Kostrzyn",
    tickets: null,
    title: "II Festiwal Kulinarny",
  },
  {
    date: "23.03.2023",
    link: "https://www.facebook.com/litowce",
    place: "Toruń",
    tickets: null,
    title: "Pojedynek Kapel",
  },
  {
    date: "23.03.2023",
    link: "https://www.facebook.com/events/744396743736230/744396753736229/",
    place: "Toruń",
    tickets: null,
    title: "Pojedynek Kapel",
  },
  {
    date: "23.04.2023",
    link: "https://www.facebook.com/events/3111065025860047",
    place: "Poznań",
    tickets: "https://www.kupbilecik.pl/imprezy/95362/Pozna%C5%84/LIT/",
    title: "LIT, The Sparrow Grass, Dekoder",
  },
  {
    date: "19.05.2023",
    link: "https://www.facebook.com/events/244915437964229",
    place: "Poznań",
    tickets: null,
    title: "Juwenalia Poznań",
  },
  {
    date: "20.05.2023",
    link: "https://www.facebook.com/piernikaliaumk",
    place: "Toruń",
    tickets: null,
    title: "Piernikalia",
  },
  {
    date: "25.05.2023",
    link: "https://www.facebook.com/events/773336930923813",
    place: "Poznań",
    tickets: null,
    title: "Gala B4KwB",
  },
  {
    date: "28.05.2023",
    link: "https://www.facebook.com/GuitarDaysFestival",
    place: "Kramsk",
    tickets: null,
    title: "Guitar Days Festival",
  },
  {
    date: "21.07.2023",
    link: "https://www.facebook.com/RockOkop",
    place: "Skępe",
    tickets: null,
    title: "RockOkop Festiwal",
  },
  {
    date: "22.07.2023",
    link: "https://www.facebook.com/profile.php?id=100090447309548",
    place: "Tleń",
    tickets: null,
    title: "BórFest Festiwal",
  },
];
