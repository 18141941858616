import React from "react";

import { IMAGES_PATH } from "../../const";
import { COLORS } from "../../theme/colors";
import { Container } from "../common/container";
import { Section } from "../common/section";
import { SectionContent } from "../common/section-content";
import { SectionHeader } from "../common/section-header";
import { SectionImage } from "../common/section-image";
import { ContactWrapper } from "./contact.styled";

export const Contact = () => (
  <Section color={COLORS.semiDark} id="kontakt">
    <Container>
      <SectionHeader color={COLORS.semiDark} title="Kontakt" />
      <SectionImage align="left" alt="Kontakt" src={`${IMAGES_PATH}/3.jpg`} />
      <SectionContent align="right">
        <ContactWrapper>
          <i className="bi-envelope" />
          E-mail: kontakt@litband.pl
        </ContactWrapper>
      </SectionContent>
    </Container>
  </Section>
);
