import styled from "styled-components";

import { MOBILE } from "../../../const";
import { COLORS } from "../../../theme/colors";

export const Link = styled.a`
  align-items: center;
  border: none;
  color: ${COLORS.accent};
  cursor: pointer;
  display: flex;
  font-size: 22px;
  font-weight: bold;
  padding: 10px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;

  &:after {
    background-color: ${COLORS.accent};
    border: -10px;
    bottom: 5px;
    content: "";
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    transform: scaleX(0);
    transition: transform ease-in 0.1s;
    width: 100%;
  }

  &:hover {
    &:after {
      transform: scaleX(100%);
    }
  }

  @media ${MOBILE} {
    margin-top: 10px;
    padding: 10px 0;
  }
`;
