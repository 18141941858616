import styled, { keyframes } from "styled-components";

import { ZLAYERS } from "../../../const";
import { COLORS } from "../../../theme/colors";

const loaderAnimation = keyframes`
 0% { opacity:1 }
 70% { opacity: 1 }
 100% { opacity: 0; display: none; }
`;

const loaderImageAnimation = keyframes`
 0% { transform: scale(80%); opacity: 0 }
 100% { transform: scale(100%); opacity: 1 }
`;

export const LoaderWrapper = styled.div`
  align-items: center;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-name: ${loaderAnimation};
  background: ${COLORS.dark};
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${ZLAYERS.loader};

  & img {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-name: ${loaderImageAnimation};
  }
`;
