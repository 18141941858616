import {
  APPLE_MUSIC_URL, IMAGES_PATH, SPOTIFY_URL, TIDAL_URL, YT_URL,
} from "../../const";

export const streaming = [
  {
    alt: "Spotify",
    href: SPOTIFY_URL,
    src: `${IMAGES_PATH}/icons/spotify-icon.svg`,
  },
  {
    alt: "Apple Music",
    href: APPLE_MUSIC_URL,
    src: `${IMAGES_PATH}/icons/apple-music-icon.svg`,
  },
  {
    alt: "Tidal",
    href: TIDAL_URL,
    src: `${IMAGES_PATH}/icons/tidal-icon-white.svg`,
  },
  // {
  //   alt: "Deezer",
  //   href: "#",
  //   src: `${IMAGES_PATH}/icons/deezer-icon.svg`,
  // },
  {
    alt: "Youtube",
    href: YT_URL,
    src: `${IMAGES_PATH}/icons/yt-icon.svg`,
  },
];
