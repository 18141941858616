import styled from "styled-components";

import { MOBILE } from "../../../const";

export const SectionContentStyled = styled.div`
  background: rgba(22, 19, 21, 0.8);
  left: ${({ align }) => (align === "left" ? 0 : "auto")};
  padding: 30px;
  position: absolute;
  right: ${({ align }) => (align === "right" ? 0 : "auto")};
  top: 90px;
  width: 600px;

  @media ${MOBILE} {
    background: transparent;
    width: 100%;
    top: 30px;
    position: relative;
    padding: 30px 0;
  }
`;
