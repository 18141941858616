import styled from "styled-components";

import { MOBILE } from "../../../const";
import { COLORS } from "../../../theme/colors";

export const SectionHeaderH2 = styled.h2`
  font-size: 72px;
  margin: 20px 0 60px;
  text-align: center;
  width: 100%;

  @media ${MOBILE} {
    font-size: 36px;
  }
`;

export const SectionHeaderText = styled.span`
  background-color: ${({ color }) => color};
  display: inline-block;
  padding: 0 20px;
  position: relative;
  z-index: 1;
`;

export const SectionHeaderSeparator = styled.span`
  background: ${COLORS.accent};
  display: inline-block;
  height: 2px;
  left: 0;
  position: absolute;
  top: 50px;
  width: 100%;

  @media ${MOBILE} {
    top: 25px;
  }
`;
