import React from "react";

import { Link } from "../link";
import {
  Item,
  ItemDate,
  ItemInfo,
  ItemTitle,
  NoTicket,
} from "./list-item.styled";

export const LIST_ITEM_TYPES = {
  event: "event",
  news: "news",
};

export const ListItem = ({
  type,
  date,
  place,
  link,
  title,
  tickets = null,
  disabled,
}) => (
  <Item disabled={disabled}>
    <ItemInfo>
      <div>
        <ItemDate>
          {date && (
            <>
              <i className="bi-calendar-date" />
              {date}
            </>
          )}
          {place && (
            <>
              <i className="bi-pin-map" />
              {place}
            </>
          )}
        </ItemDate>
        {type === LIST_ITEM_TYPES.event ? (
          <a href={link} rel="noreferrer noopener" target="_blank">
            <ItemTitle>{title}</ItemTitle>
          </a>
        ) : (
          <span>{title}</span>
        )}
      </div>
    </ItemInfo>
    {type === LIST_ITEM_TYPES.event ? (
      <div>
        {tickets ? (
          <Link href={tickets} rel="noreferrer noopener" target="_blank">
            Bilety
          </Link>
        ) : (
          <NoTicket>Wstęp wolny</NoTicket>
        )}
      </div>
    ) : (
      <Link href={link} rel="noreferrer noopener" target="_blank">
        Więcej
      </Link>
    )}
  </Item>
);
