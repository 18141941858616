import { Turn as Hamburger } from "hamburger-react";
import React, { useState } from "react";

import { IMAGES_PATH } from "../../const";
import { useIsMobile } from "../../hooks";
import {
  HamburgerWrapper,
  HeaderWrapper,
  Logo,
  Nav,
  SocialLink,
  SocialWrapper,
} from "./header.styled";
import { menu } from "./menu";
import { socials } from "./socials";

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useIsMobile();
  const showMobileMenu = isOpen || !isMobile;

  return (
    <HeaderWrapper>
      <Logo alt="LIT Logo" src={`${IMAGES_PATH}/LIT_logo_glowing_small.png`} />
      {showMobileMenu && (
        <>
          <Nav>
            <ul>
              {menu.map(({ link, label }) => (
                <li key={`menu-${label}`}>
                  <a href={link} onClick={() => setIsOpen(false)}>
                    {label}
                  </a>
                </li>
              ))}
            </ul>
          </Nav>
          <SocialWrapper>
            {socials.map(({ name, link }) => (
              <SocialLink
                key={name}
                href={link}
                rel="noreferrer noopener"
                target="_blank"
              >
                <i className={`bi-${name}`} />
              </SocialLink>
            ))}
          </SocialWrapper>
        </>
      )}
      <HamburgerWrapper onClick={() => setIsOpen(!isOpen)}>
        <Hamburger toggled={isOpen} />
      </HamburgerWrapper>
    </HeaderWrapper>
  );
};
