export const menu = [
  {
    label: "Koncerty",
    link: "#koncerty",
  },
  {
    label: "Newsy",
    link: "#newsy",
  },
  {
    label: "Wideo",
    link: "#wideo",
  },
  {
    label: "Kontakt",
    link: "#kontakt",
  },
];
