import React from "react";
import { Parallax } from "react-scroll-parallax";

import { useIsMobile } from "../../../hooks";
import { SectionContentStyled } from "./section-content.styled";

export const SectionContent = ({ align, children }) => {
  const { isMobile } = useIsMobile();

  return (
    <Parallax disabled={isMobile} speed={16}>
      <SectionContentStyled align={align}>{children}</SectionContentStyled>
    </Parallax>
  );
};
