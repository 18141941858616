export const PUBLIC_PATH = process.env.PUBLIC_URL || "/";
export const IMAGES_PATH = `${PUBLIC_PATH}images/`;
export const FILES_PATH = `/${PUBLIC_PATH}files/`;
export const MOBILE_WIDTH = 600;
export const MOBILE = `(max-width: ${MOBILE_WIDTH}px)`;
export const ZLAYERS = {
  header: 1,
  loader: 2,
};

export const VIDEO_URL = "https://www.youtube.com/embed/7qTXxP6NS34";
export const LYRICS_VIDEO_URL = "https://www.youtube.com/embed/P6xfdXVNdyY";
export const OFFICIAL_VIDEO_URL = "https://www.youtube.com/embed/VyiNl-hzrJg";
export const NEWS_URL = "https://litband.pl/fb.php";

export const FB_URL = "https://www.facebook.com/litowce/";
export const IG_URL = "https://www.instagram.com/_litowce_/";
export const YT_URL = "https://www.youtube.com/channel/UCM1-lGfVm7vr5eLu8TnQh8g";
export const SPOTIFY_URL = "https://open.spotify.com/artist/0axhGJWYkI9LuFVKorPwT8";
export const TIKTOK_URL = "https://www.tiktok.com/@litowce";
export const APPLE_MUSIC_URL = "https://music.apple.com/fm/artist/lit/1663676087";
export const TIDAL_URL = "https://listen.tidal.com/artist/36601740";
