import styled from "styled-components";

import { COLORS } from "../../theme/colors";

export const ContactWrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: 22px;
  height: 150px;
  margin: 10px 0;

  & i {
    color: ${COLORS.accent};
    margin-right: 10px;
  }

  @media (max-width: 600px) {
    font-size: 20px;
    position: relative;
    top: -30px;
    font-size: 18px;

    a {
      margin: 0 0 0 10px;
    }
  }
`;
