import React from "react";
import { Parallax } from "react-scroll-parallax";

import { useIsMobile } from "../../../hooks";
import { SectionImg } from "./section-image.styled";

export const SectionImage = ({ alt, src, align }) => {
  const { isMobile } = useIsMobile();

  return (
    <Parallax disabled={isMobile} speed={5}>
      <SectionImg align={align} alt={alt} src={src} />
    </Parallax>
  );
};
